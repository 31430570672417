.details-sider {
  height: 100%;
}

.list__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.issue__description {
  display: flex;
  justify-content: space-between;
}

.worklog-list__title {
  display: flex;
  justify-content: space-between;
}
