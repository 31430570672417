.login-form__button {
  width: 100%;
}

.login-form__error {
  padding-top: 0.5rem;
}

.jira-link {
  margin-left: 4px;
}
