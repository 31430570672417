.worklogs {
  list-style: none;
  margin: 0;
  padding: 0;
}

.worklog-list-item :not(:last-child) {
  margin-right: 0.5rem;
}

.callendar-cell__header {
  display: flex;
}
.callendar-cell__total-time {
  text-align: start;
}

.callendar-cell__day {
  flex: 1 0 auto;
}

.calendar-saturday {
  background-color: #f5f5f5;
}

.calendar-holiday {
  background-color: #fff1f0;
}