.main-layout {
  min-height: 100vh;
}
.main-layout__content {
  display: flex;
  flex-direction: column;
}
.main-layout__header {
  padding: 0;
}
