.worklog-calendar__header {
  margin: 0 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.worklog-calendar__header > * {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.worklog-calendar__total-summary {
  flex: 1 1 auto;
}

.worklog-calendar__user {
  margin-right: 1rem;
}

.worklog-calendar__button-add {
  margin: 0 1rem;
}

.ant-fullcalendar-header .ant-radio-group {
  display: none;
}
